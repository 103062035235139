<template>
    <svg viewBox="0 0 32 32" class="editFullscreenSVG" :style="{ width: size + 'px' }" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
        <path d="M4 12 L12 12 12 4 M20 4 L20 12 28 12 M4 20 L12 20 12 28 M28 20 L20 20 20 28" />
    </svg>
</template>

<script>
export default {
   name: 'editFullscreenSVG',
   props: {
    size: {
      type: [Number, String],
      default: 24
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>