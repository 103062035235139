<template>
    <svg viewBox="0 0 24 24" class="fullscreenSVG" :style="{ width: size + 'px' }" fill="none">
        <path d="M9.00002 3.99998H4.00004L4 9M20 8.99999V4L15 3.99997M15 20H20L20 15M4 15L4 20L9.00002 20" :stroke="fill" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
export default {
   name: 'fullscreenSVG',
   props: {
    size: {
      type: [Number, String],
      default: 24
    },
    fill: {
      type: String,
      default: 'none'
    }
  }
}
</script>