<!-- BoxUI.vue -->
<template>	
	<div class="box" :class="classbox" >
		<div class="header" :class="classheader">
			<div v-if="slots.accordian" v-b-toggle="'collapse-' + id" class="title">
				<AccordiancaretSVG /> <slot name="title"></slot>
			</div>
			<div v-else class="title">
				<slot name="title"></slot>
			</div>
			<slot name="button" :onClick="onClick"></slot>
		</div>
		<div class="content"
		v-if="$slots.default || $slots.leftcol || $slots.rightcol"
		:class="[ typeof classcontent === 'string' ? classcontent : Object.keys(classcontent || {}).join(' '), 
           { 'columns': $slots.leftcol || $slots.rightcol } ]">
			<!-- default content -->
			<slot></slot>
			<!-- two column content -->
			<div v-if="slots.leftcol" class="half-left">
				<slot name="leftcol"></slot>
			</div>
			<div v-if="slots.rightcol" class="half-right">
				<slot name="rightcol"></slot>
			</div>
		</div>
		<div v-if="slots.accordian" class="accordian">
			<b-collapse :id="'collapse-' + id" :accordion="'objections'" role="tabpanel" class="copy">
			  <div class="content"><slot name="accordian"></slot></div>
			</b-collapse>
		</div>
	</div>
</template>

<script setup>
	import { useSlots, ref, onMounted } from 'vue'
	import AccordiancaretSVG from '../SVG-icons/accordiancaretSVG.vue';
	const slots = useSlots()
	
	defineProps(['classbox', 'classheader', 'classcontent', 'title'])
	
	
	function onClick() {
	 // this.isActive = !this.isActive;
	}
	
	const id = ref(null)
	
	onMounted(() => {
	  id.value = Math.random().toString(36).substr(2, 9) // Generate a unique ID
	})
	
</script>