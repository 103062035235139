<template>
  <div id="ai-operator-container">
    <UIBox classbox="hello" class="m-0" :class="{ loading: isLoading }">
      <template v-slot:title>
        <span class="bold">
          AI Operator Tracking
        </span>
      </template>
      <template>
        <div v-if="isLoading" class="loader-wrapper">
          <div class="loader">
            <span></span>
          </div>
        </div>
        <template v-else>
          <template>
            <div class="filter">
              <div class="filterBody flex-row align-items-center flex-nowrap">
                <div class="form-group select d-flex align-items-center mr-4">
                  <label class="form-label" style="white-space: nowrap; font-weight: 400; font-size: 14px; margin: 0; margin-right: 10px;">Date Range</label>
                  <select v-model="sortOption" class="form-control" style="width:150px;">
                    <option value="last_30_days" selected>Last 30 Days</option>
                    <option value="last_60_days">Last 60 Days</option>
                    <option value="last_90_days">Last 90 Days</option>
                    <option value="last_180_days">Last 180 Days</option>
                    <option value="last_years">Last year</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
          <div :class="`sales-table-container sales-table-container${role ? role.toLowerCase() : ''}`">
            <table class="sales-table">
              <!-- Header Row 1 -->
              <thead class="sticky-header sticky-header-top">
                <tr>
                  <th>Date</th>
                  <th>Day</th>
                  <th>LLM Cost</th>
                  <th>Calls</th>
                  <th>Transfers</th>
                  <th>Cost per Transfer</th>
                  <th>Calls per Transfer</th>
                  <th>Sales</th>
                  <th>Transfers per Sale</th>
                  <th>Cost per Sale</th>
                </tr>
              </thead>

              <!-- Body Rows -->
              <tbody>
                <tr v-for="(row, index) in computedData" :key="index">
                  <td>{{ row.date }}</td>
                  <td>{{ row.day }}</td>
                  <td>${{ row.llmCost.toFixed(2) }}</td>
                  <td>{{ row.calls }}</td>
                  <td>{{ row.transfers }}</td>
                  <td>${{ row.costPerTransfer }}</td>
                  <td>{{ row.callsPerTransfer }}</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>

              <!-- Footer Row (Totals) -->
              <tfoot class="sticky-header sticky-header-bottom">
                <tr>
                  <td colspan="2">Total</td>
                  <td>${{ totalLLMCost.toFixed(2) }}</td>
                  <td>{{ totalCalls }}</td>
                  <td>{{ totalTransfers }}</td>
                  <td>${{ totalCostPerTransfer }}</td>
                  <td>{{ totalCallsPerTransfer }}</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </template>
      </template>
    </UIBox>
  </div>
</template>

<script>
import AuthService from "../../_services/AuthService.js";
import UIBox from '../../components/uibox.vue';

export default {
  components: {
    AuthService, UIBox
  },
  data() {
    return {
      isLoading: true,
      role: "",
      userId: "",
      reportData: [],
      sortOption: "last_30_days",
    };
  },
  computed: {
    computedData() {
      return this.reportData.map(row => ({
        ...row,
        costPerTransfer: row.transfers > 0 ? (row.llmCost / row.transfers).toFixed(2) : '0.00',
        callsPerTransfer: row.transfers > 0 ? Math.round(row.calls / row.transfers) : 0,
      }));
    },
    totalLLMCost() {
      return this.reportData.reduce((sum, row) => sum + row.llmCost, 0);
    },
    totalCalls() {
      return this.reportData.reduce((sum, row) => sum + row.calls, 0);
    },
    totalTransfers() {
      return this.reportData.reduce((sum, row) => sum + row.transfers, 0);
    },
    totalCostPerTransfer() {
      return this.computedData.reduce((sum, row) => sum + parseFloat(row.costPerTransfer), 0).toFixed(2);
    },
    totalCallsPerTransfer() {
      return Math.round(this.computedData.reduce((sum, row) => sum + parseFloat(row.callsPerTransfer), 0));
    },
  },
  methods: {
    resetScroll() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
    },
    async fetchReportData() {
      try {
        this.isLoading = true;

        const response = await fetch(`https://123avatars.com/v2/ai-operator-tracking?range=${this.sortOption}`);
        const data = await response.json();

        this.reportData = data;
      } catch (error) {
        console.error('Error fetching sales data:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    sortOption() {
      this.fetchReportData();
    }
  },
  async mounted() {
    this.resetScroll();

    await this.initUsers();
    await this.fetchReportData();
  }
};
</script>

<style scoped lang="scss">
$interactive: var(--interactive);

.sales-table-container {
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.sales-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.sales-table th,
.sales-table td {
  white-space: nowrap;
  border: 1px solid #000;
  padding: 10px 20px;
  font-weight: 400;
}

.sticky-header {
  position: sticky;

  &.sticky-header-left {
    left: 0;
    z-index: 0;

    &.index-6 {
      z-index: 6;
    }
  }

  &.sticky-header-right {
    right: 0;
    z-index: 0;

    &.index-6 {
      z-index: 6;
    }
  }

  &.sticky-header-top {
    top: 0;
    z-index: 5;
  }

  &.sticky-header-bottom {
    bottom: 0;
    z-index: 5;
  }
}

.sales-table-containeradmins tfoot,
.sales-table-containeradmins .sticky-header {
  background-color: #f2e2dd;
}

.sales-table-containerroaming_closer tfoot,
.sales-table-containerroaming_closer .sticky-header {
  background-color: #dbecee;
}

.sales-table-containermanagers tfoot,
.sales-table-containermanagers .sticky-header {
  background-color: #dfe5f7;
}

.sales-table-containeroperators tfoot,
.sales-table-containeroperators .sticky-header {
  background-color: #e3f2eb;
}

#ai-operator-container {
  ::v-deep(.hello){
    height: calc(100vh - 105px - 60px);
    display: flex;
    flex-direction: column;
  }

  ::v-deep(.hello .content) {
    overflow: hidden;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
  }

  ::v-deep(.hello.loading .content) {
    position: relative;
    flex-grow: 1;
    padding: 0;
  }

  ::v-deep(.hello.loading .content .loader-wrapper) {
    position: absolute;
    background-color: transparent;
  }

  ::v-deep(.hello.loading .content .loader-wrapper .loader) {
    border-color: $interactive rgba(255, 255, 255, 0);
  }
}
</style>