<template>
  <div>
    <div class="flex items-center justify-between" style="padding-left: 10px; margin-top: 20px;">
      <span class="font-bold text-1xl">Selected Lead Count By Industry: {{ formatNumberString(totalLeads) }}</span>
      <div>
        <span class="font-bold text-1xl" style="margin-right: 10px;">Remaining Credits: {{
          formatNumberString(remainingCredits) }}</span><br />
        <span class="font-bold text-1xl" style="margin-right: 10px;">Remaining Daily Leads to Broadcast: {{
          formatNumberString(remainingDaily) }}</span><br />
        <span class="font-bold text-1xl" style="margin-right: 10px;">Max Daily Leads to Broadcast: {{
          formatNumberString(maxDaily) }}</span><br />
        <span class="font-bold text-1xl" style="margin-right: 10px;">Credit Brakdown: 1 Lead = 0.05 Credits. 1 Credit is
          equal to $1 dollar.</span><br />
      </div>
    </div>
    <div class="flex items-center gap-2" style="padding-left: 10px; margin-top: 20px;">
      <span class="font-bold text-1xl">Leads To Send:</span>
      <input type="text" class="form-control" style="width: 200px; margin-top:10px;" v-model="displayValue" @input="handleInput($event.target.value)"
        @blur="handleBlur" @focus="handleFocus">
      <span v-if="showError" class="error-message">
        {{ errorMessage }}
      </span>
    </div>
    <div style="padding: 10px">
      <div class="flex items-center gap-2" style="font-weight: bold">
        Cost of Broadcast (Credits) : {{ (displayValue.replace(',', '') * 0.05).toLocaleString('en-US') }} credits
      </div>
      <div class="flex items-center gap-2" style="font-weight: bold">
        Remaining Credit Balance : {{ Math.abs(remainingCredits - (displayValue.replace(',', '') * 0.05)).toLocaleString('en-US') }} credits
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AuthService from '../../_services/AuthService';

export default {
  name: 'LeadsToSend',
  props: {
    leadsToSend: {
      type: Number,
      required: false,
      default: 0
    },
    totalLeads: {
      type: Number,
      required: true
    },
    selectedIndustries: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      displayValue: '',
      showError: false,
      errorMessage: '',
      userId: '',
      role: '',
      remainingCredits: 0,
      internalLeadsToSend: 0,
      maxDaily: 0,
      remainingDaily: 0,
    }
  },
  computed: {
    maxLeadsFromCredits() {
      return this.remainingCredits * 20;
    }
  },
  async mounted() {
    await this.initUser();
    await this.fetchRemainingCredits();
    // Initialize internal value
    this.internalLeadsToSend = this.leadsToSend;
    await this.getMaxDailyBroadcastsPerUser();
    await this.getRemainingDailyBroadcastsPerUser();
  },
  methods: {
    formatNumberString(number) {
      if (number === null || number === undefined || number === '') return '0';

      try {
        const num = typeof number === 'string' ? Number(number.replace(/,/g, '')) : Number(number);
        if (isNaN(num)) return '0';

        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch (error) {
        console.error('Error formatting number:', error);
        return '0';
      }
    },
    async fetchRemainingCredits() {
      let target = `https://123avatars.com/v2/sales-reps/${this.userId}`;
      let response = await axios.get(target);
      this.remainingCredits = response.data[0].credits;
    },
    async getMaxDailyBroadcastsPerUser() {
      try {
        const maxDailyResult = await axios.get('https://123avatars.com/v2/sms-broadcast/max-daily');
        this.maxDaily = maxDailyResult.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getRemainingDailyBroadcastsPerUser() {
      try {
        const remainingDailyResult = await axios.get('https://123avatars.com/v2/sms-broadcast/remaining-daily', {
          params: {
            sub: this.userId
          }
        });

        this.remainingDaily = this.maxDaily - remainingDailyResult.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    handleInput(value) {
      // Helper function to update internal state and show error
      const updateState = (leads, errorMessage = '') => {
        this.internalLeadsToSend = leads;
        this.displayValue = Number(leads).toLocaleString('en-US');
        this.showError = !!errorMessage;
        this.errorMessage = errorMessage;
      };

      // Remove any non-numeric characters and parse the number
      const numericValue = String(value).replace(/[^0-9]/g, '');
      let numberValue = numericValue ? parseInt(numericValue, 10) : 0;

      // Start with default state (no error)
      updateState(numberValue);

      // Validate all constraints
      let errorMessage = '';

      if (numberValue > this.remainingDaily) {
        numberValue = this.remainingDaily;
        errorMessage = `Only ${this.remainingDaily.toLocaleString('en-US')} leads left to broadcast today`;
      }

      if (numberValue > this.maxLeadsFromCredits) {
        numberValue = Math.min(numberValue, this.maxLeadsFromCredits);
        errorMessage = `You only have credits for ${this.maxLeadsFromCredits.toLocaleString('en-US')} leads`;
      }

      if (numberValue > this.totalLeads) {
        numberValue = Math.min(numberValue, this.totalLeads);
        errorMessage = `Cannot exceed ${this.totalLeads.toLocaleString('en-US')} leads`;
      }

      // Update state with the most restrictive constraint
      if (errorMessage) {
        updateState(numberValue, errorMessage);

        // Auto-hide error message after 3 seconds
        setTimeout(() => {
          this.showError = false;
        }, 3000);
      }

      // Emit the update
      this.$emit('update:leadsToSend', this.internalLeadsToSend);
    },
    handleBlur(event) {
      this.handleInput(event.target.value);
    },
    handleFocus() {
      //this.displayValue = this.internalLeadsToSend.toString();
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    },
  },
  watch: {
    totalLeads: {
      immediate: true,
      handler() {
        this.displayValue = '';
        this.internalLeadsToSend = 0;
      }
    },
    remainingCredits: {
      immediate: true,
      handler(newValue) {
        const maxAllowed = Math.min(this.totalLeads, this.maxLeadsFromCredits);
        if (this.internalLeadsToSend > maxAllowed) {
          this.internalLeadsToSend = maxAllowed;
          this.displayValue = maxAllowed.toLocaleString();
          this.$emit('update:leadsToSend', this.internalLeadsToSend);
        }
      }
    }
  }
}
</script>

<style scoped>
.form-control {
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: 10px;
}
</style>