import { render, staticRenderFns } from "./liveTransferTracking.vue?vue&type=template&id=621e8d85&scoped=true"
import script from "./liveTransferTracking.vue?vue&type=script&lang=js"
export * from "./liveTransferTracking.vue?vue&type=script&lang=js"
import style0 from "./liveTransferTracking.vue?vue&type=style&index=0&id=621e8d85&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621e8d85",
  null
  
)

export default component.exports