<template>
  <div id="pipeline-container">
    <div class="pipeline-header m-0">
      <div class="tabs">
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: activeTab === index }"
          @click="changeTab(index)"
        >
          <div class="tab-label">
            <div class="overlay"></div>
            <span class="tab-count">{{ tab.count.toLocaleString('en-US') }}</span>
            <span>{{ tab.label }}</span>
          </div>
          <div class="tab-info" v-if="tab.tooltip" v-html="tab.tooltip"></div>
        </button>
      </div>
    </div>

    <UIBox classbox="hello" class="mt-4">
      <template v-slot:title>
        <span class="pipeline-title">
          <div>
            <span class="bold">{{ tabs[activeTab].label }} </span>{{ `| ${tabs[activeTab].count.toLocaleString('en-US')} Lead${tabs[activeTab].count > 1 ? `s` : ``}` }}
          </div>
          <button v-if="role === 'Admins'" v-b-modal="'editStages'">Edit stages</button>
        </span>
      </template>
      <template>
        <div class="filter">
          <div class="filterBody flex-row align-items-center flex-nowrap">
            <div class="form-group select d-flex align-items-center mr-4">
              <label class="form-label" style="white-space: nowrap; font-weight: 400; font-size: 14px; margin: 0; margin-right: 10px;">Sort by</label>
              <select v-model="sortOption" @change="fetchLeads" class="form-control" style="width:200px;">
                <option v-if="activeTab === 0" value="starred_first" selected>Starred First</option>
                <option value="most_inbound" :selected="activeTab !== 0">Most Inbound Messages</option>
                <option value="most_talk_time">Most Talk Time</option>
                <option value="a_z">A-Z</option>
                <option value="z_a">Z-A</option>
              </select>
            </div>
            <div class="search-container m-0">
              <div :class="`search-box${role ? role.toLowerCase() : ''}`">
                <input type="text" placeholder="Search by company name or owner name" v-model="searchQuery" @keyup.enter="fetchLeads" />
                <button :class="`search-icon${role ? role.toLowerCase() : ''}`" @click="fetchLeads">
                  <i class="fa fa-search" style="color: #FFF !important" />
                </button>
              </div>
            </div>
            <div class="ml-3">{{ loading === false && tabs[activeTab].count != totalRows ? `${totalRows.toLocaleString('en-US')} Result${totalRows > 1 ? `s` : ``}` : `` }}</div>
          </div>
          <div class="timezone-filter mt-4 flex-wrap">
            <div
              class="checkbox-container"
              v-for="timezone in timezonesWithTime"
              :key="timezone.name"
              @click="toggleTimezone(timezone.name)"
            >
              <td style="float: left; position: relative;">
                <CheckedSVG
                  v-if="selectedTimezones.includes(timezone.name)"
                  :size="20"
                  class="checkbox-checked"
                  :fill="roleColor()"
                  :stroke="roleColor()"
                />
                <UncheckedSVG
                  v-else
                  :size="20"
                  class="checkbox-unchecked"
                  fill="none"
                  :stroke="roleColor()"
                />
              </td>
              <label
                class="font-bold"
                style="float: left; position: relative; margin: 0; margin-left: 5px;"
              >
                {{ timezone.formatted }}
              </label>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div class="table-fullwidth">
          <table class="table call-backs-table" aria-controls="tagstable">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Owner Name</th>
                <th>State</th>
                <th>Local Time</th>
                <th>Total Talk Time</th>
                <th>Texts</th>
                <th>Calls</th>
                <th style="width: 0%;">Stage</th>
                <th style="width: 0%;"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading">
                <td colspan="9" class="text-center">Leads are loading...</td>
              </tr>
              <tr v-else-if="leads.length === 0">
                <td colspan="9" class="text-center">No leads found.</td>
              </tr>
              <tr v-else v-for="lead in leads" :key="lead.id">
                <td>{{ lead.company_name }}</td>
                <td>{{ lead.owner }}</td>
                <td>{{ lead.state }}</td>
                <td>{{ lead.formatted_local_time }}</td>
                <td>{{ convertSeconds(lead.total_talk_time) }}</td>
                <td>{{ lead.texts.toLocaleString('en-US') }}</td>
                <td>{{ lead.calls.toLocaleString('en-US') }}</td>
                <td style="width: 0%;">
                  <div class="select">
                    <select
                      class="form-control"
                      style="width:150px;"
                      @change="updatePipelineStage(lead.lead_id, $event.target.value)"
                    >
                      <option
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :value="tab.stage"
                        :selected="tab.stage === lead.stage"
                      >
                        {{ tab.label }}
                      </option>
                    </select>
                  </div>
                </td>
                <td style="width: 0%;">
                  <div class="flex items-center gap-2" style="display: flex !important; align-items: center; gap: 0.5rem">
                    <div style="cursor: pointer" @click="starUnstar($event, lead)">
                      <starSVG v-if="lead.starred" :size="24" :fill="roleColor()" />
                      <starOutlineSVG v-if="!lead.starred" :size="24" :fill="roleColor()" />
                    </div>
                    <button class="btn outline" @click="makeDirectCall(lead)">
                      <phoneSVG :size="16" :fill="roleColor()" />
                    </button>
                    <button type v-b-modal.callbackModal class="btn outline" @click="setCallBack($event, lead)">
                      <callincomingSVG :size="16" :fill="roleColor()" />
                    </button>
                    <button class="btn outline" @click="sendDirectMessage($event, lead)">
                      <chatSVG :size="16" :fill="roleColor()" />
                    </button>
                    <button class="btn outline" @click="setTags($event, lead)">
                      <tagsSVG :size="16" :fill="roleColor()" />
                    </button>
                    <button class="btn outline" @click="callProspect(lead)">
                      <launchindialerSVG :size="24" :fill="roleColor()" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom-input">

          <div class="per-page">
            <div class="select">
              Per Page
              <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchLeads">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>

          <template>
            <div class="pagin">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage"
                aria-controls="tagstable">
                <template #first-text>
                  <PaginleftdoubleSVG />
                </template>
                <template #prev-text>
                  <PaginleftSVG />
                </template>
                <template #next-text>
                  <PaginrightSVG />
                </template>
                <template #last-text>
                  <PaginrightdoubleSVG />
                </template>
              </b-pagination>
            </div>
          </template>
        </div>

      </template>
      <template>
        <b-modal v-model="sendMessageModal" title="Send Message" hide-footer>
          <div class="form-container">
            <div style="margin: 10px; width: 100%;">
              <textarea v-model="directMessageText" class="prompt-input" placeholder="Enter your message here..." rows="3"
                style="
                  width: 440px;
                  resize: none;
                  max-height: 90px;
                  overflow-y: auto;
                  border-style: solid;
                  border-width: 1px;
                  border-color: #ccc;
                "></textarea>
            </div>
            <div class="button-group call-button-right-aligned gap-2">
              <button class="btn solid danger" type="button" @click="performSendOfDirectMessage"
                style="margin-right: 10px;">Send</button>
              <button class="btn solid danger" type="button" @click="sendMessageModal = false">Cancel</button>
            </div>
          </div>
        </b-modal>
      </template>
      <template>
        <b-modal v-model="messageTags" title="Add Tags" hide-footer>
          <div class="form-container">
            <div style="margin: 10px; width: 100%;">
              <div class="tags-container">
                <div class="tags-list">
                  <span v-for="(tag, index) in parseTags(rowTags)" :key="index" :class="{
                    badge: true,
                    'badge-primary': true,
                    [`tag-badge${role ? role.toLowerCase() : ''}`]: true
                  }">
                    {{ tag }}
                    <button class="badge-close-btn" @click="removeTag(tag)" aria-label="Remove tag">
                      ×
                    </button>
                  </span>
                </div>
                <div class="tag-input-wrapper">
                  <input v-model="newTag" @keyup.enter="addTag" type="text" class="tag-input" placeholder="Add tag..." style="width: 435px;"/>
                </div>
              </div>
            </div>
            <div class="button-group call-button-right-aligned gap-2">
              <button class="btn solid danger" type="button" @click="performSetTags"
                style="margin-right: 10px;">Save</button>
              <button class="btn solid danger" type="button" @click="messageTags = false">Cancel</button>
            </div>
          </div>
        </b-modal>
      </template>
      <Callbacks :type="'NEW'" :prospect="company" :userId="userId" v-if="company" />
      <b-modal v-if="true" id="tagsModal" title="Add/Edit Tags" size="lg">
        <template #modal-header="{ close }" class="header">
          <div>Set Tags For <span class="bold">{{ company.company }}</span></div>
          <button class="btn" @click="close()">
            <closeSVG />
          </button>
        </template>
      </b-modal>
    </UIBox>

    <b-modal v-if="role === 'Admins'" id="editStages" title="Edit Stages" size="md" @show="prepareEditingTabs">
      <template #modal-header="{ close }" class="header">
        <div style="color: white;"><span class="bold">Edit Stages</span></div>
        <button class="btn" @click="close()">
          <closeSVG fill="#fff" />
        </button>
      </template>
      <div class="d-flex flex-wrap" style="gap: 15px;">
        <div class="form-group m-0" v-for="(tab, index) in editingTabs" :key="index">
          <input
            type="text"
            class="form-control"
            v-model="tab.label"
          />
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="buttons" style="gap: 10px;">
          <b-button class="btn outline" @click="close()">Close</b-button>
          <b-button class="btn solid" @click="saveAllTabs(close)">Save</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AuthService from "../../_services/AuthService.js";
import axios from "axios";
import UIBox from '../../components/uibox.vue';
import TooltipSVG from '../../components/SVG-icons/tooltipSVG.vue';
import starSVG from '../../components/SVG-icons/starSVG.vue';
import emailSVG from '../../components/SVG-icons/emailSVG.vue';
import starOutlineSVG from '../SVG-icons/starOutlineSVG.vue';
import phoneSVG from '../../components/SVG-icons/phoneSVG.vue';
import chatSVG from '../../components/SVG-icons/chatSVG.vue';
import tagsSVG from '../../components/SVG-icons/tagsSVG.vue';
import launchindialerSVG from '../../components/SVG-icons/launchindialerSVG.vue';
import callincomingSVG from '../SVG-icons/callincomingSVG.vue';
import PaginleftdoubleSVG from '../../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from '../../components/SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from '../../components/SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from '../../components/SVG-icons/paginrightSVG.vue';
import closeSVG from "../../components/SVG-icons/closeSVG.vue";
import CheckedSVG from '../../components/SVG-icons/checkedSVG.vue';
import UncheckedSVG from '../../components/SVG-icons/unCheckedSVG.vue';
import Callbacks from '../../pages/sales/Callbacks.vue';

export default {
  components: {
    AuthService, UIBox, TooltipSVG, starSVG, emailSVG, starOutlineSVG, phoneSVG, chatSVG, tagsSVG, launchindialerSVG,
    callincomingSVG, PaginleftdoubleSVG, PaginleftSVG, PaginrightdoubleSVG, PaginrightSVG, closeSVG, CheckedSVG, UncheckedSVG,
    Callbacks
  },
  props: {
    userTimezone: {
      type: String,
      required: true
    }
  },
  watch: {
    currentPage() {
      this.fetchLeads();
    },
  },
  data() {
    return {
      tabs: [
        { label: "All Leads", stage: 0, count: 0, tooltip: "(Live Transfers)<br>(Broadcasts)<br>(Marketplace)" },
        { label: "Made Contact", stage: 1, count: 0, tooltip: "(Spoke to Prospect)<br>(Exchanged Messages)" },
        { label: "Service Offered", stage: 2, count: 0, tooltip: "(Text to Pay)<br>(Demo)<br>(Pitched)" },
        { label: "Ready to Sign Up", stage: 3, count: 0, tooltip: "(Starred)" },
      ],
      editingTabs: [],
      activeTab: 0,
      leads: [],
      searchQuery: "",
      sortOption: "starred_first",
      rowsPerPage: 10,
      currentPage: 1,
      totalRows: 0,
      role: "",
      userId: "",
      loading: true,
      cancelToken: null,
      messageTags: false,
      rowTags: [],
      selectedRowIdForTag: 0,
      newTag: '',
      lastUpdateMinute: new Date().getMinutes(),
      uniqueTimezones: [],
      selectedTimezones: [],
      timezonesWithTime: [],
      company: '',
      sendMessageModal: false,
      selectedRow: {},
      directMessageText: '',
    };
  },
  methods: {
    async fetchCounts() {
      const response = await axios.get(`https://123avatars.com/v2/pipeline-leads-count`, {
        params: {
          userId: this.userId,
          role: this.role,
        }
      });
      this.tabs.forEach((tab, index) => {
        tab.count = response.data.totals[`count-${index}`];
      });
    },
    async fetchLeads() {
      this.loading = true;
      this.leads = [];

      if (this.cancelToken) {
        this.cancelToken.cancel("Cancelled due to a new request.");
      }

      const currentCancelToken = axios.CancelToken.source();
      this.cancelToken = currentCancelToken;

      try {
        localStorage.setItem('rowsPerPage', this.rowsPerPage);

        const response = await axios.get(`https://123avatars.com/v2/pipeline-leads`, {
          cancelToken: this.cancelToken.token,
          params: {
            userId: this.userId,
            role: this.role,
            stage: this.tabs[this.activeTab].stage,
            search: this.searchQuery,
            sort: this.sortOption,
            limit: this.rowsPerPage,
            page: this.currentPage,
            timezones: this.selectedTimezones,
          },
        });

        this.leads = response.data.leads;
        this.totalRows = response.data.total;

        this.updateLocalTimes();
        this.fetchCounts();
        await this.fetchTimezones();
      } catch (error) {
        console.error("Error fetching leads:", error);
      } finally {
        if (this.cancelToken === currentCancelToken) {
          this.loading = false;
          this.cancelToken = null;
        }
      }
    },
    changeTab(index) {
      this.activeTab = index;
      this.totalRows = 0;

      this.sortOption = index === 0 ? "starred_first" : "most_inbound";

      this.currentPage = 1;
      this.fetchLeads();
    },
    convertSeconds(seconds) {
      if (seconds >= 3600) {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return `${hours}h ${remainingMinutes}m`;
      } else if (seconds >= 60) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
      } else {
        return `${seconds}s`;
      }
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
    },
    async updatePipelineStage(lead_id, newStage) {
      try {
        this.loading = true;

        const response = await axios.put(`https://123avatars.com/v2/pipeline-leads-stage`, {
          lead_id,
          newStage: parseInt(newStage, 10),
        });

        if (response.status === 200) {
          this.fetchLeads();
        } else {
          alert(`${response.data.message || "Something went wrong"}`);
        }
      } catch (error) {
        console.error("Error updating pipeline stage:", error);
      }
    },
    async fetchTabs() {
      try {
        const response = await axios.get("https://123avatars.com/v2/pipeline-stages");
        this.tabs = response.data.stages.map((stage, index) => ({
          label: stage.label,
          tooltip: stage.tooltip,
          stage: index,
          count: 0,
        }));

        await this.fetchCounts();
      } catch (error) {
        console.error("Error fetching pipeline stages:", error);
      }
    },
    prepareEditingTabs() {
      this.editingTabs = this.tabs.map(tab => ({
        label: tab.label,
        tooltip: tab.tooltip,
        stage: tab.stage,
      }));
    },
    async saveAllTabs(close) {
      try {
        await axios.put("https://123avatars.com/v2/pipeline-stages", {
          stages: this.editingTabs,
        });
        await this.fetchTabs();
        close();
      } catch (error) {
        console.error("Error saving tabs:", error);
      }
    },
    removeTag(tagToRemove) {
      const currentTags = this.parseTags(this.rowTags);
      const updatedTags = currentTags.filter(tag => tag !== tagToRemove);
      this.rowTags = JSON.stringify(updatedTags);
    },
    addTag() {
      if (!this.newTag.trim()) return; // Don't add empty tags

      const currentTags = this.parseTags(this.rowTags);
      if (currentTags.includes(this.newTag.trim())) {
        return;
      }

      const updatedTags = [...currentTags, this.newTag.trim()];
      this.rowTags = JSON.stringify(updatedTags);
      this.newTag = '';
    },
    parseTags(tags) {
      // Handle string or array input
      if (typeof tags === 'string') {
        try {
          return JSON.parse(tags);
        } catch {
          return [];
        }
      }
      return Array.isArray(tags) ? tags : [];
    },
    async performSetTags() {
      try {
        const result = await axios.post(`https://123avatars.com/v2/set-tags`, {
          id: this.selectedRowIdForTag,
          tags: this.rowTags
        });

        if (result.status === 200) {
          const updatedTags = this.parseTags(this.rowTags);
          const row = this.leads.find(lead => lead.lead_id === this.selectedRowIdForTag);

          if (row) {
            row.tags = updatedTags;
          }

          this.messageTags = false;
        } else {
          console.error("Failed to update tags:", result.data.message || "Unknown error");
        }
      } catch (error) {
      }
    },
    async setTags(e, row) {
      e.stopPropagation();
      this.rowTags = row.tags;
      this.selectedRowIdForTag = row.lead_id;
      this.messageTags = true;
    },
    getFormattedTime(timezone) {
      timezone = this.getTimezoneFromAbbreviation(timezone);

      const now = new Date();
      return new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      }).format(now);
    },
    getTimezoneFromAbbreviation(abbreviation) {
      const timezones = {
        'EST': 'America/New_York', // Eastern Standard Time
        'EDT': 'America/New_York', // Eastern Daylight Time
        'CST': 'America/Chicago', // Central Standard Time
        'CDT': 'America/Chicago', // Central Daylight Time
        'MST': 'America/Denver', // Mountain Standard Time
        'MDT': 'America/Denver', // Mountain Daylight Time
        'PST': 'America/Los_Angeles', // Pacific Standard Time
        'PDT': 'America/Los_Angeles', // Pacific Daylight Time
        'AKST': 'America/Anchorage', // Alaska Standard Time
        'AKDT': 'America/Anchorage', // Alaska Daylight Time
        'HST': 'Pacific/Honolulu', // Hawaii Standard Time
        'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
      };

      return timezones[abbreviation] || 'America/Los_Angeles';
    },
    async updateLocalTimes() {
      this.leads = this.leads.map(lead => ({
        ...lead,
        formatted_local_time: `${this.getFormattedTime(lead.timezone)} (${lead.timezone})`,
      }));

      this.timezonesWithTime = this.uniqueTimezones.map((timezone) => ({
        name: timezone,
        formatted: `${this.getFormattedTime(timezone)} (${timezone})`,
      }));
    },
    watchClock() {
      setInterval(() => {
        const currentMinute = new Date().getMinutes();
        if (currentMinute !== this.lastUpdateMinute) {
          this.lastUpdateMinute = currentMinute;
          this.updateLocalTimes();
        }
      }, 1000);
    },
    async fetchTimezones() {
      try {
        const response = await axios.get(`https://123avatars.com/v2/pipeline-timezones`);
        this.uniqueTimezones = response.data.timezones || [];

        this.updateLocalTimes();
      } catch (error) {
        console.error("Error fetching timezones:", error);
      }
    },
    toggleTimezone(timezone) {
      if (this.selectedTimezones.includes(timezone)) {
        this.selectedTimezones = this.selectedTimezones.filter((tz) => tz !== timezone);
      } else {
        this.selectedTimezones.push(timezone);
      }

      this.fetchLeads();
    },
    async starUnstar(e, row) {
      e.stopPropagation();
      try {
        row.starred = !row.starred;
        const target = 'https://123avatars.com/v2/inbox/star';
        const starred = row.starred ? 1 : 0;
        await axios.post(target, {
          id: row.id,
          starred,
          is_sales_lead: true
        });

        if (starred === 1) {
          this.$toast.info("We have also moved the lead to the 'Ready to Sign Up' folder in the pipeline.", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            style: {
              width: "440px",
            },
          });
        }

        this.fetchLeads();
      } catch (error) {
        console.error('Error opening message:', error);
      }
    },
    callProspect(row) {
      console.log(`emit ${row.phone.replace('+', '')} ${row.lead_id} ${this.userId}`);
      this.$emit('open-call-prospect', {
        salesRepId: row.manager_id,
        leadId: row.lead_id,
        phone: row.phone,
        fromInbox: false,
        makeCall: false
      });
    },
    mapCallbackData(input) {
      const formattedDate = this.getFormattedDate(this.getTimezoneFromAbbreviation(input.timezone));

      return {
        id: input.id,
        owner: input.owner || '-',
        company_name: input.company_name || '-',
        timezone: input.timezone,
        localTime: input.timeUser || '123',
        userTimezone: this.userTimezone,
        leadId: input.lead_id ? input.lead_id.toString() : '123',
        callbackTime: formattedDate
      };
    },
    getFormattedDate(timezone) {
      const date = new Date();
      const options = { 
        timeZone: timezone, 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false 
      };

      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(date);

      const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value}T${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;

      const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
      const targetDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
      const offset = (targetDate - utcDate) / 60000;

      const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
      const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, '0');
      const offsetSign = offset >= 0 ? '+' : '-';

      const offsetString = offset === 0 ? 'Z' : `${offsetSign}${offsetHours}:${offsetMinutes}`;

      return `${formattedDate}${offsetString}`;
    },
    setCallBack(e, row) {
      e.stopPropagation();
      this.company = null;
      this.company = this.mapCallbackData(row);
    },
    async performSendOfDirectMessage() {
      try {
        const result = await axios.post(`https://123avatars.com/v2/inbox/reply-to-message`, {
          leadId: this.selectedRow.lead_id,
          azureContactId: this.selectedRow.azure_contact_id,
          to: `${this.selectedRow.phone.trim()}`,
          company: this.selectedRow.company_name,
          text: this.directMessageText
        });
        console.log('result: ', result);
      } catch (error) {
      }

      this.sendMessageModal = false;
      this.directMessageText = '';
    },
    sendDirectMessage(e, row) {
      e.stopPropagation();
      this.selectedRow = row;
      this.sendMessageModal = true;
    },
    makeDirectCall(row) {
      this.$emit('open-call-prospect', {
        salesRepId: row.manager_id,
        leadId: row.lead_id,
        phone: row.phone,
        makeCall: true,
        switchProfile: false,
      });
    }
  },
  async mounted() {
    const savedRowsPerPage = localStorage.getItem('rowsPerPage');
    if (savedRowsPerPage) {
      this.rowsPerPage = parseInt(savedRowsPerPage, 10);
    }

    await this.initUsers();

    await this.fetchTimezones();
    this.selectedTimezones = [...this.uniqueTimezones];

    await this.fetchTabs();
    await this.fetchLeads();

    this.watchClock();
  },
};
</script>

<style scoped lang="scss">
$interactive: var(--interactive);

#pipeline-container {
  .pipeline-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      padding: 0;
      border: none;
      background-color: transparent;
      color: #ffffff;
      font-size: 26px;
      font-family: "Inter", system-ui;
      font-weight: 300;
      transition: color 0.35s;

      &:hover {
        color: $interactive;
      }
    }
  }

  .pipeline-header {
    .tabs {
      display: flex;
      border-radius: 20px;
      overflow: hidden;

      button{
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 15px 25px;
        gap: 7.5px;
        font-size: 17px;
        font-weight: 700;
        align-items: center;
        justify-content: flex-start;
        color: #fff;
        background-color: $interactive;
        flex-grow: 1;
        border: none;

        a {
          display: flex;
        }

        a,
        span,
        .tab-count,
        .tab-info {
          z-index: 3;
          line-height: 1;
        }

        .tab-count {
          padding: 0 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 40px;
          height: 40px;
          line-height: 1;
          font-size: 20px;
          font-weight: 700;
          border-radius: 50%;
          color: $interactive;
          background-color: #ffffff;
        }

        .tab-info {
          opacity: 0.75;
          line-height: 1.35;
          font-size: 14px;
          font-weight: 500;
        }

        .tab-label {
          display: flex;
          align-items: center;
          gap: 7.5px;
        }

        svg {
          z-index: 1;
          height: 12px;

          ::v-deep(circle) {
            fill: transparent;
            stroke: #ffffff;
          }

          ::v-deep(path) {
            fill: #ffffff;
          }
        }

        .overlay {
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          transition: background 0.35s;
        }

        &:hover {
          .overlay {
            background-color: rgba($color: #000000, $alpha: 0.25);
          }
        }

        &.active {
          cursor: default;

          .overlay {
            background-color: rgba($color: #000000, $alpha: 0.4);
          }
        }

        &::before {
          z-index: 2;
          content: "";
          position: absolute;
          transform: translate(calc(50% - 38px), -50%) rotate(-45deg) scale(1.95) skew(-15deg, -15deg);
          top: 50%;
          right: 0;
          border: solid #f7fff9;
          border-width: 0 25px 25px 0;
          display: inline-block;
          width: 75px;
          height: 75px;
        }

        &:first-child{
          padding-left: 0;
          padding-right: 35px;
        }

        &:last-child{
          padding-right: 0;
          padding-left: 35px;
        }

        &:last-child::before{
          content: unset;
        }
      }
    }
  }

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .search-box, .search-boxadmins, .search-boxmanagers, .search-boxroaming_closer, .search-boxoperators {
    position: relative;
    width: 330px;
  }

  .search-box input,  .search-boxadmins input, .search-boxmanagers input, .search-boxroaming_closer input, .search-boxoperators input {
    width: 100%;
    padding: 8px 40px 8px 16px;
    border-radius: 8px;
  }

  .search-box input {
    border: 2px solid #46A57C !important;
  }

  .search-boxadmins input {
    border: 2px solid #A73A18 !important;
  }

  .search-boxmanagers input {
    border: 2px solid #2653C7 !important;
  }

  .search-boxroaming_closer input {
    border: 2px solid #0B7D8A !important;
  }

  .search-boxoperators input {
    border: 2px solid #46A57C !important;
  }

  .search-box {
    border-color: #46A57C !important;
  }

  .search-boxadmins {
    border-color: #A73A18 !important;
  }

  .search-boxmanagers {
    border-color: #2653C7 !important;
  }

  .search-boxroaming_closer {
    border-color: #0B7D8A !important;
  }

  .search-boxoperators {
    border-color: #46A57C !important;
  }

  .search-box input:focus {
    outline: none;
    border: 2px solid #46A57C !important;
  }

  .search-boxmanagers input:focus {
    outline: none;
    border: 2px solid #2653C7 !important;
  }

  .search-boxroaming_closer input:focus {
    outline: none;
    border: 2px solid #0B7D8A !important;
  }

  .search-boxoperators input:focus {
    outline: none;
    border: 2px solid #46A57C !important;
  }

  .search-boxadmins input:focus {
    outline: none;
    border: 2px solid #A73A18 !important;
  }

  .search-icon, .search-iconadmins, .search-iconmanagers, .search-iconroaming_closer, .search-iconoperators {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 16px;
    padding: 0 12px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    border-style: none;
  }

  .search-icon {
    background-color: #46A57C !important;
    border-color: #46A57C !important;
  }

  .search-iconadmins {
    background-color: #A73A18 !important;
    border-color: #A73A18 !important;
  }

  .search-iconmanagers {
    background-color: #2653C7 !important;
    border-color: #2653C7 !important;
  }

  .search-iconroaming_closer {
    background-color: #0B7D8A !important;
    border-color: #0B7D8A !important;
  }

  .search-iconoperators {
    background-color: #46A57C !important;
    border-color: #46A57C !important;
  }
}

::v-deep(.popover-body) {
  text-align: center;
}

::v-deep(.title) {
  width: 100%;
}

.tags-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag-input-container {
  margin-top: 0.5rem;
}

.tag-input {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
  height: 24px;
}

.tag-input:focus {
  outline: none;
  border-color: #46A57C;
  box-shadow: 0 0 0 2px rgba(70, 165, 124, 0.2);
}

.tag-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.35em 0.65em;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: #46A57C !important;
  color: white;
}

.tag-badgeadmins {
  background-color: #A73A18 !important;
}

.tag-badgeroaming_closer {
  background-color: #0B7D8A !important;
}

.tag-badgemanagers {
  background-color: #2653C7 !important;
}

.tag-badgeoperators {
  background-color: #46A57C !important;
}

.badge-close-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1;
  color: white;
  background: none;
  border: none;
  opacity: 0.75;
  cursor: pointer;
}

.badge-close-btn:hover {
  opacity: 1;
}

.call-button-right-aligned {
  display: flex;
  justify-content: flex-end;
}

.table.call-backs-table tr,
.table.call-backs-table td {
  white-space: nowrap;
}

.timezone-filter {
  display: flex;
  gap: 15px;
}

.checkbox-container {
  cursor: pointer;
  display: flex;
  align-items: center;

  td {
    padding: 0;
    display: flex;
  }

  label {
    cursor: pointer;
    font-weight: 400;
  }
}
</style>
