<template>
  <div class="chat-container">
    <div class="chat-header">
      <h3>{{ headerTitle }}</h3>
      <button type="button" class="btn outline icon mute" @click="callManager" style="margin-right:5;" v-if="userType === 'Operators'">
        <PhoneSVG ></PhoneSVG>
      </button>
      <span class="close" @click="closeModal" style="margin-left:1%;">&times;</span>

    </div>
    <div class="chat-messages conversation" :class="{'h-auto': userType === 'Roaming_Closer'}" ref="chatMessages">
      <div v-for="(message, index) in messages" :key="index" :class="{'bubble-row right': 'From: Me' == getMessageSender(message), 'bubble-row left': 'From: Me' != getMessageSender(message)}">
        <div class="bubble-container">
          <div class="bubble mw-100">
            <span v-html="message.text"></span>
            <svg class="tail" :viewBox="'0 0 27 25'">
              <path v-if="'From: Me' != getMessageSender(message)" d="M26.5 24.5L12 0.5L0.5 15.5L26.5 24.5Z" />
              <path v-else d="M0 24.5L14.5 0.5L26 15.5L0 24.5Z" />
            </svg>
          </div>
          <div class="subtitle bold" v-if="userType === 'Operators'">{{ getMessageSender(message) }}</div>
          <div class="subtitle" v-if="userType === 'Operators'">{{ formatChatTimestamp(message.time) }}</div>
        </div>
        <!-- <span class="sender">{{ getMessageSender(message) }}</span>
        <span>{{ message.text }} - {{ formatChatTimestamp(message.time) }}</span> -->
      </div>
      
    </div>
    
    <div class="chat-input" v-if="userType === 'Operators'">
      <input type="text" v-model="messageInput" placeholder="Type your message..." @keyup.enter="sendMessage" />
      <button @click="sendMessage">Send</button>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';
import axios from 'axios';
import { TelnyxRTC } from '@telnyx/webrtc';
import SocketService from './socketService.js';
import PhoneSVG from '../../components/SVG-icons/phoneSVG.vue';

export default {
  components: {
    'PhoneSVG': PhoneSVG
  },
  props: {
    type: String,
    salesRepId: String,
    managerId: String,
    headerTitle: {
      type: String,
      default: 'Chat'
    },
    phoneNumber: {
      type: String
    },
    callId: {
      type: String
    },
    passedMessages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      messageInput: '',
      messages: this.passedMessages,
      socket: null,
      userId: this.$route.query.id,
      userType: this.type, // either 'REP' or 'MANAGER',
      telnyx: null,
      currentCall: null,
      isMuted: true,
      username: 'russell32925',
      password: 'gbtKZ5ym',
      callerId: '+19492884866',
      usernameTwo: 'cmharrisiii48614',
      passwordTwo: 'T0Do3WIN',
      callerIdTwo: '+13125300209',
      telnyxRTC: null,
      localAudioTrack: null,
      isListening: false,
      prospectData: null,
    };
  },
  mounted() {

  console.log('salesRepId: ', this.salesRepId);

   this.socket = SocketService.connect();

   console.log('socket: ', this.socket);

    console.log("userType: ", this.userType);
    if(this.userType === 'Managers') {
      SocketService.register(this.managerId);
    } else {
      SocketService.register(this.salesRepId);
    }
    SocketService.on('new_message', this.handleNewMessage);

    console.log('this.salesRepId: ', this.salesRepId);
    console.log('this.managerId: ', this.managerId);

    console.log('salesRepId: ', this.salesRepId);

    console.log('managerId: ', this.managerId);

    console.log('ABC salesRepId: ',  this.salesRepId);
    console.log('ABC managerId: ', this.managerId);

    this.fetchMessages();
    if(this.userType === 'Managers') {
      this.markMessagesAsRead(this.salesRepId, this.managerId);
      //this.connect();
    }
    console.log("headerTitle: ", this.headerTitle);
  },
  methods: {
    callManager() {
      console.log('callManager');
      this.$emit('call-manager', {});
    },
    async closeModal() {
      if(this.currentCall) {
        await this.currentCall.hangup();
      }
      this.$emit('close-modal');
    },
    connect() {
      let id = this.$route.query.id;
      let managerNum = '';
      let user = '';
      let pass = '';
      let callerIdNum = '';
      if (id == '0d0a642f-3e81-47cc-917b-b5c5c70c5a48') {
        managerNum = '+19492884866';
        user = this.username;
        pass = this.password;
        callerIdNum = this.callerId;
      }
      if (id == '3a67b80-1a3f-4c8f-972f-2133ff03fb2d') {
        managerNum = '+13125300209';
        user = this.usernameTwo;
        pass = this.passwordTwo;
        callerIdNum = this.callerIdTwo;
      }
      managerNum = '+19492884866';
        user = this.username;
        pass = this.password;
        callerIdNum = this.callerId;
      try {
        this.telnyxRTC = new TelnyxRTC({
          login: user,
          password: pass,
          callerIdNumber: callerIdNum,
          debug: this.enableDebugging,
          logLeveL: 'debug',
        });
      } catch (err) {
        console.error("error init listening", err)
      }
      this.telnyxRTC.on('telnyx.socket.open', () => {
        console.log('Socket opened');
      });

      this.telnyxRTC.on('telnyx.socket.error', (error) => {
        console.error('Socket error', error);
      });

      this.telnyxRTC.on('telnyx.socket.close', () => {
        console.log('Socket closed');
      });

      this.telnyxRTC.on('telnyx.ready', () => {
        console.log('Telnyx ready');
      });

      this.telnyxRTC.on('telnyx.error', (error) => {
        console.error('Telnyx error', error);
      });

      this.telnyxRTC.on('telnyx.call.incoming', (call) => {
        // this.call = call;
        console.log('Incoming call', call);
      });

      this.telnyxRTC.on('telnyx.call.answered', (call) => {
        console.log('Call answered', call);
        //this.attachStreamToAudioElement(this.call.remoteStream);
      });

      this.telnyxRTC.on('telnyx.call.hangup', (call) => {
        console.log('Call hung up', call);
      });

      this.telnyxRTC.on('telnyx_rtc.invite', (call) => {
        console.log('call: ', call);
      });

      this.telnyxRTC.on('telnyx.notification', async (notification) => {
        console.log('telnyxNotification: ', notification);
        try {
          const call = notification.call;

          console.log('call: ', call.tt);

          if (notification.type === 'callUpdate' && call.state === 'ringing') {
            if(call.options.remoteCallerNumber == "9492884866" || call.options.remoteCallerNumber == "3125300209" ||
              call.options.remoteCallerNumber == "+19492884866" || call.options.remoteCallerNumber == "+13125300209"
            ) {
              // call.answer();
              // if(this.call) {
              //   this.mergeCalls(this.call, call);
              // }
            }
          }
          if (notification.type === 'callUpdate') {
          if (notification.call.state === 'active') {
            try {
              this.listeningCall = notification.call;
              this.localAudioTrack = this.listeningCall.localStream.getAudioTracks()[0];
              this.localAudioTrack.enabled = false;
              this.attachStreamToAudioElement(notification.call.remoteStream);
            } catch (err) {
              console.error('error attaching stream to audio element: ', err);
            }
          }
        }
        } catch (err) {
          console.log("Error answering call: ", err);
        }
      });

      this.telnyxRTC.connect();
    },
    toggleMute() {
      console.log('toggleMute: ', this.isMuted);
      this.isMuted = !this.isMuted;
      if(this.localAudioTrack) {
        this.localAudioTrack.enabled = !this.localAudioTrack.enabled;
      }
      console.log('isMuted: ', this.isMuted);
    },
    makeCall(fromNum, toNum) {
      console.log("fromNum: ", fromNum);
      console.log("toNum: ", toNum);
      if (this.telnyxRTC) {
        if (toNum.length === 10) {
          toNum = '+1' + toNum;
        }
        fromNum = '+19492884866';
        this.currentCall = this.telnyxRTC.newCall({
          destinationNumber: fromNum,
          callerNumber: fromNum,
        });
        this.isListening = true;
      }
    },
    attachStreamToAudioElement(stream) {
      const audioElement = document.getElementById('manager-audio');
      audioElement.srcObject = stream;
      console.log('audioElementAttached: ', audioElement);
    },
    connectAudio() {
      if (this.currentCall) {
        this.currentCall.options.audio = true;
        this.muteCall();
      }
    },
    muteCall() {
      if (this.currentCall) {
        this.currentCall.muteAudio();
        this.isMuted = true;
      }
    },
    unmuteCall() {
      if (this.currentCall) {
        this.currentCall.unmuteAudio();
        this.isMuted = false;
      }
    },
    // toggleMute() {
    //   if (this.isMuted) {
    //     this.unmuteCall();
    //   } else {
    //     this.muteCall();
    //   }
    // },
    detachAudioElement() {
      const audioElement = document.getElementById('manager-audio');
      if (audioElement) {
        if (audioElement.srcObject) {
          // Get the tracks of the media stream
          const tracks = audioElement.srcObject.getTracks();

          // Stop each track
          tracks.forEach(track => track.stop());
        }

        audioElement.srcObject = null;  // Clear the stream
        audioElement.pause();           // Pause the audio element
      }
    },
    async fetchClientDetails() {
      const params = {
        search: this.searchQuery,
        date: this.dateFilter,
        type: this.typeFilter,
        milestone: this.milestoneFilter,
        timezone: this.timezoneFilter,
        salesRep: this.salesRepFilter,
        shelfLife: this.shelfLifeFilter,
        hours: this.hoursFilter,
        startDate: this.customStartDate,
        endDate: this.customEndDate,
        rowsPerPage: this.rowsPerPage,
        currentPage: this.currentPage,
        isOverride: true
      };
      console.log('salesRepId: ', this.salesRepId);
      params.id = this.salesRepId;
        // if(this.role == 'Admins' || this.role == 'Managers') {
        //   params.isOverride = true;
        // }
        axios.get('https://123avatars.com/v2/manager-data', { params })
          .then(response => {
            let data = response.data;
            console.log('data: ', data);
            if (data && data.leads && data.leads.length) {
            this.prospectData = {
                company_name: data.leads[0].company_name,
                phone: data.leads[0].phone,
                state: data.leads[0].state,
                owner_cell: data.leads[0].phone,
                timezone: data.leads[0].timezone,
                address: data.leads[0].Company_Street_Address,
                city: data.leads[0].Company_City,
                state: data.leads[0].Company_State,
                zipcode: data.leads[0].Company_Zipcode,
                owner: data.leads[0].Owners_Name,
                owner_email: data.leads[0].Owner_Email,
                industry: data.leads[0].Industry,
                website: data.leads[0].Website,
                typeform_responses: this.parseTypeformResponses(data.leads[0].typeform_responses),
              };
            }
            console.log('prospectData: ', this.prospectData);

          })
          .catch(error => {
            console.error('Error fetching leads:', error);
          });
    },
    cleanJsonString(jsonString) {
      try {
        // Remove control characters like newlines, tabs, and others.
        let cleanedString = jsonString
          .replace(/\\n/g, "") // Remove escaped newlines
          .replace(/\n/g, "")  // Remove actual newlines
          .replace(/\r/g, "")  // Remove carriage returns
          .replace(/\t/g, "")  // Remove tabs
          .replace(/\\'/g, "'") // Handle escaped single quotes
          .replace(/\\"/g, '"') // Handle escaped double quotes
          .replace(/\\\*/g, "*") // Remove escaped asterisks
          .replace(/\\_/g, "_") // Remove escaped underscores
          .replace(/\\\)/g, ")"); // Remove escaped parentheses

        // More cleaning can be added as needed based on what errors are encountered.

        return cleanedString;
      } catch (error) {
        console.error("Failed to clean JSON string:", error);
        return jsonString; // Return the original string if something goes wrong.
      }
    },

    parseTypeformResponses(responses) {
      let cleanedResponses = this.cleanJsonString(responses);
      let parsedResponses;

      // Step 1: Parse the cleaned JSON string safely
      try {
        parsedResponses = JSON.parse(cleanedResponses);
      } catch (error) {
        console.error("Failed to parse typeform responses after cleaning:", error);
        return [];
      }

      // Step 2: Check the structure of the parsed responses
      const formattedResponses = [];

      // Assuming parsedResponses is a key-value object, iterate through it
      let i = 0;
      
      for (const question in parsedResponses) {
        if (parsedResponses.hasOwnProperty(question)) {
          if(i == 0) {
            let _question = 'What is your name?';
            const answer = parsedResponses[question];
            formattedResponses.push({ _question, answer });
          } else if(i == (parsedResponses.length - 1)) {

          } else {
            const answer = parsedResponses[question];
            formattedResponses.push({ question, answer });
          }
          i++;
        }
      }

      return formattedResponses;
    },
    formatPhoneNumber(phoneNumber) {
        let formattedPhoneNumber = '-';
        if(phoneNumber) {
          phoneNumber = phoneNumber.toString();
          const areaCode = phoneNumber.slice(0, 3);
          const centralOfficeCode = phoneNumber.slice(3, 6);
          const stationCode = phoneNumber.slice(6, 10);
          formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
        }
        return formattedPhoneNumber;
    },
    bargeCall() {
      this.connect();
      let id = this.$route.query.id;
      this.fetchClientDetails();
      axios.get(`https://123avatars.com/v2/barge-call?id=${this.salesRepId}&managerId=${this.managerId}&callId=${this.callId}`)
        .then((resp) => {
          if(resp.data.message) {
            alert(resp.data.message);
          } else {
            this.makeCall(this.callerIdTwo, this.phoneNumber);
          }
        }).catch((err) => {
          console.error("error barging call: ", err);
        });
      console.log('id: ', id);
    },
    unmuteCall() {

    },
    getTimezone() {
      // Get the local timezone
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      console.log('timezone: ', localTimezone);

      // List of US timezones
      const usTimezones = [
        'America/New_York',    // EST/EDT
        'America/Chicago',     // CST/CDT
        'America/Denver',      // MST/MDT
        'America/Phoenix',     // MST (no DST)
        'America/Los_Angeles', // PST/PDT
        'America/Anchorage',   // AKST/AKDT
        'America/Adak',        // HST/HDT
        'Pacific/Honolulu',    // HST (no DST)
      ];

      // Check if the local timezone is a US-based timezone
      if (usTimezones.includes(localTimezone)) {
        return localTimezone;
      }

      // If not a US timezone, return PST/PDT
      return 'America/Los_Angeles';
    },
    formatChatTimestamp(timestamp) {
      const timezone = this.getTimezone();
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timezone
      };

      const date = new Date(timestamp);
      return date.toLocaleString('en-US', options);
    },
    async markMessagesAsRead(salesRepId, managerId) {
      try {
        const response = await axios.post('https://123avatars.com/v2/markMessagesAsRead', {
          sales_rep_id: salesRepId,
          manager_id: managerId
        });

        if (response.status === 200) {
          console.log('Messages marked as read successfully:', response.data.message);
          // Handle success, e.g., update UI to reflect that messages have been read
        } else if (response.status === 404) {
          console.log('No unread messages found:', response.data.message);
          // Handle case where there were no unread messages
        }
      } catch (error) {
        console.error('Error marking messages as read:', error.response?.data?.error || error.message);
        // Handle error, e.g., display an error message to the user
      }
    },
    emitEvent() {
      this.$emit('new_message', 'new_message');
    },
    handleNewMessage(message) {
      console.log('message: ', message);
      this.messages.push(message);
      this.$nextTick(this.scrollToBottom);
      this.emitEvent();
    },
    fetchMessages() {
      if (this.passedMessages.length > 0) return;

      fetch(`https://123avatars.com/v2/get-messages/${this.salesRepId}/${this.managerId}`)
        .then(response => response.json())
        .then(data => {
          this.messages = data;
          this.$nextTick(this.scrollToBottom);
        }).catch(err => {
          console.error('error fetching messages: ', err);
        });
    },
    sendMessage() {
      if (this.messageInput.trim() !== '') {
        const senderId = this.userType === null ? this.salesRepId : this.managerId;
        const message = {
          sales_rep_id: this.salesRepId,
          manager_id: this.managerId,
          sender: senderId,
          text: this.messageInput,
        };

        fetch('https://123avatars.com/v2/send-message', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(message),
        })
          .then(response => response.json())
          .then(() => {
            this.messageInput = '';
            this.$nextTick(this.scrollToBottom);
          });
      }
    },
    scrollToBottom() {
      const chatMessages = this.$refs.chatMessages;
      chatMessages.scrollTop = chatMessages.scrollHeight;
    },
    getMessageClass(message) {
      const senderId = this.userType === null ? this.salesRepId : this.managerId;
      return message.sender === senderId ? 'message-user' : 'message-manager';
    },
    getMessageSender(message) {
      if (this.userType === 'Roaming_Closer') {
        return 'From: Manager';
      } else if (this.userType === null) {
        return message.sender === this.salesRepId ? 'From: Me' : 'From: Manager';
      } else if (this.userType !== null) {
        return message.sender === this.managerId ? 'From: Me' : 'From: Sales Rep';
      }
    }
  },
};
</script>
<style>
.chat-container {
  /* Styles for chat container */
}

.bubble-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bubble {
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  position: relative;
  display: inline-block;
}

.bubble-row.right .bubble {
  align-self: flex-end;
}

.bubble-row.left .bubble {
  align-self: flex-start;
}

.tail {
  position: absolute;
  bottom: -4px;
  width: 15px;
  height: 15px;
}

.right-tail {
  right: -7px;
  transform: rotate(0deg);
}

.left-tail {
  left: -7px;
  transform: rotate(180deg);
}

.subtitle {
  font-size: 0.9rem;
  margin-top: 4px;
}

.bold {
  font-weight: bold;
}
</style>