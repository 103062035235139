<template>
  <div id="callBacksReports">
    <UIBox classbox="hello">
      <template v-slot:title>
        <span class="bold">Call Backs Report</span>
      </template>
      <template>
        <div class="filter">

          <!-- Filter Header -->
          <div class="filterHead">Filter by</div>
          <div class="filterBody">

          <!-- MANAGER FILTER -->
          <div class="filterSelect select" v-if="role === 'Admins'">
            <label for="managerFilter">Managers</label>
            <select id="managerFilter" v-model="selectedManagerId" class="form-control" @change="fetchCallbacks">
              <option value="all">All</option>
              <option v-for="manager in managers" :value="manager.id">
                {{ manager.name }}
              </option>
            </select>
          </div>

          <!-- SALES REP FILTER -->
          <div class="filterSelect select" v-if="role === 'Admins' || role === 'Managers'">
            <label for="salesRepFilter">Sales Reps / Operators</label>
            <select id="salesRepFilter" v-model="selectedSalesRepId" class="form-control" @change="fetchCallbacks">
              <option value="all">All</option>
              <option v-for="rep in salesReps" :value="rep.id">
                {{ rep.name }}
              </option>
            </select>
          </div>
          </div>

          <!-- Filter Toggle Buttons -->
          <div class="filterToggles">

            <!-- Outline class should be swapped with Solid class when button is active -->
            <button class="btn outline" @click="toggleCallBacks('future')">Show Future Call Backs</button>
            <button class="btn outline" @click="toggleCallBacks('previous')">Show Previous Call Backs</button>

            <!-- Search Box -->
            <!-- <div class="filterText">
              <input type="text" v-model="searchCallBacks" @keyup="" class="form-control outline" placeholder="Search Call Backs" />
            </div>-->
            
            <!-- Old Hidden Filter -->
            <!-- <div class="filter-container">
              <label for="salesRepFilter">Sales Rep:</label>
              <select id="salesRepFilter" v-model="selectedRep" @change="filterBySalesRep">
                <option value="all">All</option>
                <option v-for="rep in salesReps" :key="rep" :value="rep">{{ rep }}</option>
              </select>
            </div> -->
          </div>
        </div>

        <div class="container-body" id="reportContainer">
          
          <!-- Dummy Table -->
          <!-- <div class="table-fullwidth">
           <table data-v-67b980a2="" class="table call-backs-table">
            <thead data-v-67b980a2="">
              <tr data-v-67b980a2="">
                <th data-v-67b980a2="">Sales Rep</th>
                <th data-v-67b980a2="">Call Back Time (User)</th>
                <th data-v-67b980a2="">Call Back Time (Prospect)</th>
                <th data-v-67b980a2="">Prospect Timezone</th>
                <th data-v-67b980a2="">Company Name</th>
                <th data-v-67b980a2="">Contact Name</th>
                <th data-v-67b980a2="">Contact Phone</th>
                <th data-v-67b980a2="">Set Up Ago</th>
                <th data-v-67b980a2="">Total Talk Time</th>
                <th data-v-67b980a2="">Actions</th>
              </tr>
            </thead>
            <tbody data-v-67b980a2=""> -->
              <!-- Eventually I need the headline to render as a ten column spanning row -->
              <!-- We don't need a new header for each table, so it'll save a lot of space doing it this way -->
              <!-- <tr colspan="10"><h3>Headline</h3></tr>
              <tr data-v-67b980a2="" data-sales-rep="Chuck Harris" class="previous">
                <td data-v-67b980a2="">Chuck Harris</td>
                <td data-v-67b980a2="" class="call-time">05:30 PM</td>
                <td data-v-67b980a2="" class="call-time">02:30 PM</td>
                <td data-v-67b980a2="">PST</td>
                <td data-v-67b980a2="">The Union Apartments</td>
                <td data-v-67b980a2=""></td>
                <td data-v-67b980a2=""><a data-v-67b980a2="" href="tel:6192141583">(619) 214-1583</a></td>
                <td data-v-67b980a2="">-</td>
                <td data-v-67b980a2="">-</td>
                <td data-v-67b980a2="" class="action-buttons"><button data-v-67b980a2="" class="btn solid edit-btn">Edit</button><button data-v-67b980a2="" class="btn solid delete-btn">Delete</button></td>
              </tr>
            </tbody>
          </table>
          </div> -->
          <!-- End of Dummy Table -->
          
          <div class="container-body" id="reportContainer">
            <div class="table-fullwidth">
              <div v-for="group in callBackData" class="date-group">
                <h3 :style="`background-color: ${roleColor()}; color: #FFF;`">{{ formatDateWithDay(group.date) }}</h3>
                <table class="table call-backs-table" aria-controls="callbackstable">
                  <thead>
                    <tr>
                      <th style="width: 8%;">Sales Rep</th>
                      <th style="width: 8%;">Manager</th>
                      <th style="width: 8%;">Call Back Time (User)</th>
                      <th style="width: 8%;">Call Back Time (Prospect)</th>
                      <th style="width: 8%;">Prospect Timezone</th>
                      <th style="width: 15%;">Company Name</th>
                      <th style="width: 10%;">Contact Name</th>
                      <th style="width: 10%;">Contact Phone</th>
                      <th style="width: 10%;">Set Up Ago</th>
                      <th style="width: 5%;">Total Talk Time</th>
                      <th style="width: 20%;">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in group.data" :data-sales-rep="row.salesRep">
                      <td>{{ row.salesRep }}</td>
                      <td>{{ row.managerName }}</td>
                      <td class="call-time">{{ formatLocalTimeByTimezone(row.callbackTime, userTimezone) }}</td>
                      <td class="call-time">{{ formatLocalTimeByTimezone(row.callbackTime, getTimezoneFromAbbreviation(row.timezone)) }}</td>
                      <td class="word-wrap-break">{{ row.timezone }}</td>
                      <td>{{ row.company }}</td>
                      <td>{{ row.contactName }}</td>
                      <td><button class="btn outline icon" @click="callProspect(row.salesRepId, row.leadId, row.contactPhone)"><PhoneSVG /></button></td>
                      <td>{{ row.setUpAgo }} days</td>
                      <td>{{ formatSeconds(row.talkTime) }} </td>
                      <td class="action-buttons">
                        <b-button type v-b-modal.callbackModal class="btn solid" @click="openEditCallback(row)">Edit Callback</b-button>
                        <button class="btn solid delete-btn" @click="deleteRow(group.date, row.id)">Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- Bottom Input -->
      <div class="bottom-input">
        
        <div class="per-page">
          <div class="select">
            Per Page
            <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchCallbacks">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>
      
        <template>
          <div class="pagin">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage" aria-controls="callbackstable" @change="fetchCallbacks">
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>
      
      </div>
    </UIBox>
    <Callbacks :type="'EDIT'" :prospect="company" :userId="userId" v-if="company" @callbacks-closed="handleCallbacksClosed" />
  </div>
</template>
  
<script>
import Axios from 'axios';
import { DateTime } from 'luxon';
import UIBox from './uibox.vue';
import PaginleftdoubleSVG from './SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from './SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from './SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from './SVG-icons/paginrightSVG.vue';
import CloseSVG from './SVG-icons/closeSVG.vue';
import CheckSVG from './SVG-icons/checkSVG.vue';
import AuthService from '../_services/AuthService.js';
import PhoneSVG from './SVG-icons/phoneSVG.vue';

import Callbacks from "../pages/sales/Callbacks.vue";

import axios from 'axios';


export default {

  components: { 
    UIBox,
	  PaginleftdoubleSVG,
	  PaginleftSVG,
	  PaginrightdoubleSVG,
	  PaginrightSVG,
	  CloseSVG,
	  CheckSVG,
    PhoneSVG,
    'Callbacks': Callbacks
  },

  props: {
    userTimezone: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      role: '',
      userId: '',
      timezone: '',
      salesReps: ['Rep A', 'Rep B', 'Rep C', 'Rep D', 'Rep E'],
      managers: [],
      selectedRep: 'all',
      callBackData: [],
      showModal: false,
      editData: {},
      userTime: '',
      timeDifference: 0,
      selectedManagerId: 'all',
      selectedSalesRepId: 'all',
      rowsPerPage: 50,
      currentPage: 1,
      rowSearch: '',
      perPageSearch: '',
      isFutureFilter: 'true',
      totalRows: 0,
      showCallbacks: true,
      company: null
    };
  },
  computed: {
    uniqueManagers() {
      const managers = this.callBackData.flatMap(group => group.data.map(row => ({ managerId: row.managerId, managerName: row.managerName })));
      return [...new Map(managers.map(manager => [manager.managerId, manager])).values()];
    },
    uniqueSalesReps() {
      const salesReps = this.callBackData.flatMap(group => group.data.map(row => ({ salesRepId: row.salesRepId, salesRepName: row.salesRepName })));
      return [...new Map(salesReps.map(rep => [rep.salesRepId, rep])).values()];
    },
    filteredData() {
      if (this.selectedRep === 'all') {
        return this.callBackData;
      }
      return this.callBackData.map(group => ({
        ...group,
        data: group.data.filter(row => row.salesRep === this.selectedRep)
      }));
    }
  },
  methods: {
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role.toLowerCase()] || '#46A57C';
    },
    handleCallbacksClosed() {
      this.fetchCallbacks();
    },
    mapCallbackData(input) {
      return {
        id: input.id,
        owner: input.contactName || '-',
        company_name: input.company || '-',
        timezone: input.timezone,
        localTime: input.timeUser || '123',
        userTimezone: this.userTimezone,
        leadId: input.leadId ? input.leadId.toString() : '123',
        callbackTime: input.callbackTime ? input.callbackTime : null
      };
    },
    openEditCallback(callback) {
      this.company = Object.assign({}, this.mapCallbackData(callback));
    },
    formatSeconds(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      const hoursDisplay = hours > 0 ? `${hours}h ` : "";
      const minutesDisplay = minutes > 0 ? `${minutes}m ` : "";
      const secondsDisplay = `${secs}s`;

      return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim();
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
    },
    callProspect(salesRepId, leadId, phone) {
      console.log('emit');
      this.$emit('open-call-prospect', {
        salesRepId: salesRepId,
        leadId: leadId,
        phone: phone,
        makeCall: true
      });
    },
    isFuture(callbackTime) {
      console.log('cal;lbackTime: ', callbackTime);
      const currentTime = new Date().getTime(); // Get current time in milliseconds
      const targetTime = new Date(callbackTime).getTime(); // Get callback time in milliseconds
      console.log('currentTime: ', currentTime);
      console.log('targetTime: ', targetTime);
      console.log('boolean: ', (targetTime > currentTime));
      return targetTime > currentTime;
    },
    formatLocalTime(dateString) {
      // Parse the date string to a Date object
      const date = new Date(dateString);

      // Options for formatting the time
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true // This will format the time in 12-hour format with AM/PM
      };

      // Format the time using the toLocaleTimeString method
      const localTimeString = date.toLocaleTimeString('en-US', options);

      return localTimeString;
    },
    formatLocalTimeByTimezone(dateString, timezone) {
      // Parse the date string to a Date object
      const date = new Date(dateString);

      if (!timezone) {
        timezone = this.getUserTimezone();
      }
      //console.log('timezone: ', timezone);

      // Convert the date to the specified timezone
      const options = {
        timeZone: timezone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: true // This will format the time in 12-hour format with AM/PM
      };

      // Format the time using the toLocaleTimeString method with the specified timezone
      const localTimeString = date.toLocaleTimeString('en-US', options);

      return localTimeString;
    },
    getUserTimezone() {
      return this.getTimezone();
    },
    getTimezone() {
      // Get the local timezone
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // List of US timezones
      const usTimezones = [
        'America/New_York', // EST/EDT
        'America/Chicago', // CST/CDT
        'America/Denver', // MST/MDT
        'America/Phoenix', // MST (no DST)
        'America/Los_Angeles', // PST/PDT
        'America/Anchorage', // AKST/AKDT
        'America/Adak', // HST/HDT
        'Pacific/Honolulu', // HST (no DST)
      ];

      // Check if the local timezone is a US-based timezone
      if (usTimezones.includes(localTimezone)) {
        return localTimezone;
      }

      // If not a US timezone, return PST/PDT
      return 'America/Los_Angeles';
    },
    getTimezoneFromAbbreviation(abbreviation) {
      const timezones = {
        'EST': 'America/New_York', // Eastern Standard Time
        'EDT': 'America/New_York', // Eastern Daylight Time
        'CST': 'America/Chicago', // Central Standard Time
        'CDT': 'America/Chicago', // Central Daylight Time
        'MST': 'America/Denver', // Mountain Standard Time
        'MDT': 'America/Denver', // Mountain Daylight Time
        'PST': 'America/Los_Angeles', // Pacific Standard Time
        'PDT': 'America/Los_Angeles', // Pacific Daylight Time
        'AKST': 'America/Anchorage', // Alaska Standard Time
        'AKDT': 'America/Anchorage', // Alaska Daylight Time
        'HST': 'Pacific/Honolulu', // Hawaii Standard Time
        'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
      };

      return timezones[abbreviation] || 'America/Los_Angeles';
    },
    getCognitoSub() {
      // Get the JWT from local storage
      const token = localStorage.getItem("user");

      if (!token) {
        return null; // Return null if no token is found
      }

      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = JSON.parse(atob(token.split('.')[1]));

      // Return the 'sub' value from the payload
      return payload.sub || null;
    },
    getUserRoleFromToken() {
      // Get the JWT from local storage 
      const token = localStorage.getItem("userToken");

      if (!token) {
        return null; // Return null if no token is found
      }

      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = JSON.parse(atob(token.split('.')[1]));

      // Check if the 'cognito:groups' claim exists in the payload
      const groups = payload['cognito:groups'];

      if (!groups || groups.length === 0) {
        return null; // Return null if no groups are found
      }

      // Assuming the role is determined by the first group in the array
    return groups[0]; // Or you can adjust this to match your application's logic
    },
    formatPhoneNumber(phoneNumber) {
      phoneNumber = phoneNumber.toString();
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
      }
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const stationCode = phoneNumber.slice(6, 10);
      const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
      return formattedPhoneNumber;
    },
    async fetchCallbacks() {
      let path = '';
      // let role = this.getUserRoleFromToken();
      // if(!role) {
      //   let id = this.getCognitoSub();
      //   path = '?salesRepId=' + id;
      // }

      let target = 'https://123avatars.com/v2/callbacks';
      let role = this.role;
      if (role == 'Admins') {
        target += `?role=Admins&salesRepFilter=${this.selectedSalesRepId}&managerFilter=${this.selectedManagerId}`;
      } else if (role == 'Managers') {
        let sub = await AuthService.getCognitoSub();
        target += '?id=' + sub + `&role=Managers&salesRepFilter=${this.selectedSalesRepId}&managerFilter=${this.selectedManagerId}`;
      } else {
        let sub = await AuthService.getCognitoSub();
        target += '?id=' + sub + '&role=Operators';
      }
      target += `&rowsPerPage=${this.rowsPerPage}&currentPage=${this.currentPage}&isFuture=${this.isFutureFilter}`;
      const response = await axios.get(target);
      this.callBackData = response.data.data;
      this.totalRows = response.data.totalRows;
      //this.toggleCallBacks('future');
    },
    formatDateWithDay(dateStr) {
      const date = DateTime.fromISO(dateStr);

      // Format the date as "Day of the Week, MM/DD/YYYY"
      return `${date.toFormat('cccc')}, ${date.toFormat('M/dd/yyyy')}`;
    },
    formatDate(dateStr) {
      const date = DateTime.fromISO(dateStr, { zone: 'utc' }).setZone(this.getUserTimezone());
      const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
      return date.toLocaleString(options);
    },
    formatPhone(phone) {
      return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
    },
    toggleCallBacks(type) {
      if(type == 'previous') {
        this.isFutureFilter = 'false';
      }
      if(type == 'future') {
        this.isFutureFilter = 'true';
      }
      this.fetchCallbacks();
      // const futureGroups = document.querySelectorAll('.date-group.future');
      // const previousGroups = document.querySelectorAll('.date-group.previous');

      // if (type === 'future') {
      //   futureGroups.forEach(group => group.classList.remove('hidden'));
      //   previousGroups.forEach(group => group.classList.remove('hidden'));
      //   previousGroups.forEach(group => group.classList.add('hidden'));
      // } else {
      //   futureGroups.forEach(group => group.classList.remove('hidden'));
      //   previousGroups.forEach(group => group.classList.remove('hidden'));
      //   futureGroups.forEach(group => group.classList.add('hidden'));
      // }
    },
    filterBySalesRep() {
      // Logic handled by computed property
    },
    async deleteRow(date, id) {
      try {
        await axios.delete(`https://123avatars.com/v2/callbacks/${id}`);
        this.callBackData = this.callBackData.map(group => {
          if (group.date === date) {
            return {
              ...group,
              data: group.data.filter(row => row.id !== id)
            };
          }
          return group;
        });
      } catch (error) {
        console.error('Error deleting callback:', error);
      }
    },
    openEditModal(row) {
      this.editData = { ...row };
      console.log('editData: ', this.editData);
      this.showModal = true;
      this.updateUserTimeAndDifference();
    },
    closeModal() {
      this.showModal = false;
    },
    updateUserTimeAndDifference() {

      // Parse the callback time in UTC
      const utcTime = DateTime.fromISO(this.editData.callbackTime, { zone: 'utc' });

      // Convert to the prospect's local time using their timezone
      const prospectTime = utcTime.setZone(this.editData.timezone);

      // Format prospectTime for the datetime-local input element
      this.editData.timeProspect = prospectTime.toFormat('yyyy-MM-dd\'T\'HH:mm');

      // Set the current time for the prospect's timezone
      const currentProspectTime = DateTime.now().setZone(this.editData.timezone);
      this.editData.timeUser = currentProspectTime.toLocaleString(DateTime.DATETIME_FULL);

      // Convert to the user's local time using the browser's local timezone
      const userTime = utcTime.setZone(DateTime.local().zoneName);
      this.userTime = userTime.toLocaleString(DateTime.DATETIME_FULL);

      // Calculate the time difference between the prospect's timezone and the user's local timezone
      const prospectOffset = prospectTime.offset;
      const userOffset = userTime.offset;
      this.timeDifference = Math.abs(prospectOffset - userOffset) / 60; // Convert minutes to hours

      console.log('timeProspect: ', this.editData.timeProspect);
      console.log('currentProspectTime (timeUser): ', this.editData.timeUser);
    },
    onChangeUpdateUserTimeAndDifference() {
      console.log('editTimeProspect: ', this.editData.timeProspect);
      console.log('editTimeCallbackTime: ', this.editData.callbackTime);

      // Parse the timeProspect (which is in the prospect's timezone)
      const prospectTime = DateTime.fromISO(this.editData.timeProspect, { zone: this.editData.timezone });

      // Convert the prospect time to UTC
      const utcTime = prospectTime.setZone('utc').toISO();

      // Update the callbackTime with the new UTC time
      this.editData.callbackTime = utcTime;

      // Set the current time for the prospect's timezone
      const currentProspectTime = DateTime.now().setZone(this.editData.timezone);
      this.editData.timeUser = currentProspectTime.toLocaleString(DateTime.DATETIME_FULL);

      // Convert to the user's local time using the browser's local timezone
      const userTime = prospectTime.setZone(DateTime.local().zoneName);
      this.userTime = userTime.toLocaleString(DateTime.DATETIME_FULL);

      // Calculate the time difference between the prospect's timezone and the user's local timezone
      const prospectOffset = prospectTime.offset;
      const userOffset = userTime.offset;
      this.timeDifference = Math.abs(prospectOffset - userOffset) / 60; // Convert minutes to hours

      console.log('timeProspect: ', this.editData.timeProspect);
      console.log('currentProspectTime (timeUser): ', this.editData.timeUser);
      console.log('callbackTime (in UTC): ', this.editData.callbackTime);
    },
    formatTime(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return `${hours}:${minutes} ${ampm}`;
    },
    saveCallbackChanges() {
      // Convert the existing callbackTime to UTC
      const utcTime = DateTime.fromISO(this.editData.callbackTime, { zone: this.editData.timezone }).setZone('utc').toISO();

      // Prepare the payload with the UTC callbackTime
      const payload = {
        ...this.editData,
        callbackTime: utcTime
      };

      // Update the local data
      this.callBackData = this.callBackData.map(group => {
        if (group.date === this.editData.date) {
          return {
            ...group,
            data: group.data.map(row => (row.contactPhone === this.editData.contactPhone ? this.editData : row))
          };
        }
        return group;
      });

      // Send the updated data to the backend using axios
      axios.put(`https://123avatars.com/v2/callbacks/${this.editData.id}`, payload)
        .then(response => {
          this.closeModal();
          this.$emit('callback-updated', response.data);
          this.fetchCallbacks();
        })
        .catch(error => {
          console.error('Failed to save callback changes:', error);
        });
    },
    async fetchSalesReps() {
      try {
      const params = {
        role: this.role,
        userId: this.userId
      };
      let response = null;
      if(this.role === 'Admins') {
        response = await axios.get('https://123avatars.com/v2/sales-reps');
      }
      if(this.role === 'Managers') {
        response = await axios.get(`https://123avatars.com/v2/users?filter=Operators&managerId=${this.userId}`);
      }
      this.salesReps = response.data
        .map(rep => ({
            id: rep.sub,
            name: rep.firstName + ' ' + rep.lastName
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
      console.error('Error fetching sales reps:', error);
      }
    },
    async fetchManagers() {
      try {
      const params = {
        role: this.role,
        userId: this.userId
      };
      let response = null;
      if(this.role === 'Admins') {
        response = await axios.get('https://123avatars.com/v2/users?filter=Managers');
      }
      if(this.role === 'Managers') {
        response = await axios.get(`https://123avatars.com/v2/users?filter=Managers`);
      }
      this.managers = response.data
        .map(rep => ({
            id: rep.sub,
            name: rep.firstName + ' ' + rep.lastName
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
      console.error('Error fetching sales reps:', error);
      }
    },
  },
  async mounted() {
    await this.initUser();
    this.fetchSalesReps();
    this.fetchManagers();
    await this.fetchCallbacks();
  }
};
</script>
<style scoped>
  #reportContainer .table {
    width: 100%;
    table-layout: fixed;
  }

  #reportContainer th,
  #reportContainer td {
    text-align: left;
  }
  #reportContainer h3 {
    background-color: #B6E7C3;
  }

  .word-wrap-break {
    word-wrap: break-word;
  }
</style>