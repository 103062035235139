<template>
    <div class="container" style="width:100%; margin-top:5%;">
      <callbacksReport :userTimezone="userTimezone"></callbacksReport>
    </div>
  </template>
  
  <script>
  import callbacksReport from "../../pages/sales/CallbacksReport.vue"
  
  export default {
    components: {
      'callbacksReport': callbacksReport
    },
    props: {
      userTimezone: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        
      };
    },
    
    methods: {
      
    }
  };
  </script>
  
  <style scoped>
  
  </style>